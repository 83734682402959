@charset "UTF-8";
//メディアクエリ挿入
@mixin media_query($width:730){
	@media screen and (max-width: $width+px){
		@content;
	}
}


#dissolution{
  font-family: "source-han-sans-japanese", Verdana, "游ゴシック", '游ゴシック体', YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  
  .header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    
    &_inner{
      font-size: 2rem;
      padding: .3em 20px;
      color: #fff;
      
      h1{
        width: 100%;
        text-align: center;
      }
    }
  }
  
  .main{
    width: 100%;
    margin-top: 130px;
    margin-bottom: 100px;
    
    @include media_query(){
    	margin-top: 70px;
    	margin-bottom: 10px;
    }
  }
  
  .article{
    max-width: 690px;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
    
    h2{
      font-size: 2rem;
      color: #000;
      font-weight: 400;
      margin-bottom: 2em;
      text-align: center;
      
      @include media_query(){
      	font-weight: 1.8rem;
      	line-height: 1.5;
      	margin-bottom: 1.6rem;
      }
    }
    
    p{
      margin-bottom: 1em;
      line-height: 1.7;
      font-weight: 200;
      
      @include media_query(){
      	font-size: 1.5rem;
      	margin-bottom: .8em;
      }
    }
    
    .right{
      text-align: right;
      font-size: 1.5rem;
      margin-top: 30px;
    }
  }
  
  
  .footer{
    padding: 20px 20px 0;
    
    @include media_query(){
    	margin: 0;
    }
    
    &_inner{
      max-width: 950px;
      margin: 0 auto;
      padding: 0;
    }
    
    ul{
      display: flex;
      flex-wrap: wrap;
      margin-left: -3.333%;
      
      @include media_query(){
      	margin-left: -3%;
      }
      
      li{
        width: 30%;
        margin-left: 3.333%;
        margin-bottom: 20px;
        
        @include media_query(){
        	width: 47%;
        	margin: 0 0 20px 3%;
        }
        
        a{
          
          &:hover{
            opacity: .7;
          }
          
          img{
            width: 100%;
            height: auto;
            vertical-align: bottom;
          }
        }
      }
    }
  }
  
  .copy{
    text-align: center;
    color: #777;
    font-size: 1.2rem;
    padding: 0 20px 10px;
    font-weight: 100;
  }
}